@font-face {
  font-family:'Lora Regular';
  src: url('../public/fonts/Lora/Lora\ Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  display: flex;
  flex-direction: column;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: 'Lora Regular';
}

#bannerImage {
  width: 100%;
  height: 20vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.text-content {
  width: 70%;
  padding: 40px;
  margin-left: -10%;
  font-family: 'Lora Regular';
  font-size: 17px;
}

.fade-in-image { 
    animation: fadeIn 2s, rise 2s;
 }

 .fade-out-image { 
  display: none;
 }

 .nav-visible { 
  visibility: visible;
}

.nav-invisible { 
  visibility: hidden;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 0.7; }
  }

  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  @keyframes rise {
    0% {
        transform: translateY(30px);
      }

    100% {
      transform: translateY(0px);
      }
}

.fade-in-text { 
    animation: fadeInText 2.5s, rise 2.5s;
    font-family: 'Lora Regular';
 }

 button{

    font-family: "Lora Regular";
    font-size: 18px;

 }

@keyframes fadeInText {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

@media (min-width: 768px) {
  .App-header {
    flex-direction: row;
  }

  #bannerImage {
    width: 50%;
    height: 100vh;
  }

  .text-content {
    width: 40%;
    margin-left: 2%;
    position: relative;
  }
}
